import React from 'react';
import ReactDOM from 'react-dom';
import ModalHelper from '@components/collection/components/globals/helpers/modal.helper';
import { isMobile, isBrowser } from "react-device-detect";
import { withRouter } from 'react-router-dom';
import LocalStorageService from '@components/collection/components/globals/backend-services/localStorage.service';
import CmsService from '@components/collection/components/globals/backend-services/cms/cms.service';
import store from '../redux/store';
import UtilsHelper from '../helpers/utils.helper.jsx';
import { LayoutTemplate } from './layout.template';
import { MODALS } from './../modals/modals';
import { User, CustomerConfig } from '../redux/actions';
import LoginService from '@components/collection/components/globals/backend-services/header/login.service';
import RestService from '@components/collection/components/globals/backend-services/rest.service';
import LanguageHelper from './../helpers/language.helper';

class LayoutComponent extends React.Component {
    menu;

    state = {
        banner: null
    };

    parseBTag() {
        var urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.get('btag') && !UtilsHelper.getCookie('btag')) {
            UtilsHelper.setCookie('btag', urlParams.get('btag'), 30);
        }
    }

    openModalByUrl() {
        ModalHelper.router.listen((location, action) => {
            if (ModalHelper.getSearchParam('modal')) {
                let modal = Object.keys(ModalHelper.modals).filter(modal => ModalHelper.modals[modal].route === ModalHelper.getSearchParam('modal'))[0];
                if (!ModalHelper.modals[modal].opened) {
                    ModalHelper.toggleModal(ModalHelper.modals[modal], ModalHelper.getSearchParam('selectedTab') || true, true);
                }
            } else {
                ModalHelper.closeAllOpenedModals();
                window.scrollTo(0, 1);
            }
        });

        if (ModalHelper.getSearchParam('modal')) {
            let modal = Object.keys(ModalHelper.modals).filter(modal => ModalHelper.modals[modal].route === ModalHelper.getSearchParam('modal'))[0];
            ModalHelper.toggleModal(ModalHelper.modals[modal]);
        }
    }

    componentDidMount() {
        ModalHelper.router = this.props.history;
        window['router'] = this.props.history;
        this.parseBTag();

        // MODAL LOADED
        ModalHelper.setHelperParams(MODALS, ReactDOM.unmountComponentAtNode);

        isMobile && CmsService.getBanner('MENU_BANNER', LocalStorageService.get('lang'), isBrowser ? 'DESKTOP' : 'MOBILE').then((data) => {
            if (data.length > 0) {
                this.setState({ banner: data });
            }
        });

        this.openModalByUrl();
        this.setUserInformation();
        document.body.addEventListener('LOGIN', this.setUserInformation);
        document.body.addEventListener('LOGOUT', () => this.logout());
    }

    logout = () => {

        LoginService.logout();
        store.dispatch(User.logout());
        store.dispatch(CustomerConfig.setConfig({}));

        ModalHelper.router.push(`/${store.getState().language.data}/home`);
        ModalHelper.closeAllOpenedModals();

        isMobile && UtilsHelper.toggleMobileNavigation();

        document.querySelector('#layout-modal-component').querySelector('.custom-modal-wrapper').classList.remove('shown')
        document.querySelector('#layout-modal-component').querySelector('.custom-modal-wrapper').classList.add('hidden');
    }

    setUserInformation() {
        LoginService.currentCustomer(true).then((data) => {
            data['isLoggedIn'] = data.acceptTermsAndConditions === true;
            store.dispatch(User.setUserData(data));
            LanguageHelper.initPrivateTranslations();

            RestService.initCustomerConfig().then((customerConfig) => {
                store.dispatch(CustomerConfig.setConfig(customerConfig));
            });
        }).catch(() => {
            store.dispatch(User.setUserData({
                isLoggedIn: false
            }));
        });
    }

    toggleMenu() {
        this.menu.toggle()
    }

    slides(banner) {
        if (!banner || banner.slides) return;
        banner.slides = this.state.banner[0].layers;
    }

    render() {
        return LayoutTemplate.call(this);
    }
}
export default withRouter(LayoutComponent);