
export const User = {
    setUserData: (data) => (Object.assign({ type: 'SET_USER_DATA', data })),
    changeUserData: (data) => (Object.assign({ type: 'CHANGE_USER_DATA', data })),
    logout: (data) => (Object.assign({ type: 'LOGOUT', data }))
}

export const UserBalance = {
    setUserBalance: (data) => (Object.assign({ type: 'SET_USER_BALANCE', data })),
}

export const GeneralConfig = {
    setConfig: (data) => (Object.assign({ type: 'SET_CONFIG', data }))
}

export const CustomerConfig = {
    setConfig: (data) => (Object.assign({ type: 'SET_CUSTOMER_CONFIG', data }))
}

export const Language = {
    setLang: (data) => (Object.assign({ type: 'SET_LANG', data }))
}

export const KycDocuments = {
    setDocuments: (data) => (Object.assign({ type: 'SET_DOCUMENTS', data }))
}

export const DefaultLimits = {
    setDefaultLimits: (data) => (Object.assign({ type: 'SET_DEFAULT_LIMITS', data }))
}

export const SelfExlusions = {
    setSelfExlusions: (data) => (Object.assign({ type: 'SET_SELF_EXCLUSIONS', data }))
}

export const Translations = {
    setTranslations: (data) => (Object.assign({ type: 'SET_TRANSLATIONS', data }))
}

export const RealityCheckTimer = {
    updateTimer: (data) => (Object.assign({ type: 'UPDATE_TIMER', data }))
}

export const ProviderMaintenanceMode = {
    setMaintenanceMode: (data) => (Object.assign({ type: 'SET_MAINTENANCE_MODE', data }))
}

export const Menus = {
    setMenus: (data) => (Object.assign({ type: 'SET_MENUS', data })),
    setActiveMenu: (data) => (Object.assign({ type: 'SET_ACTIVE_MENU', data }))
}

export const CMSPages = {
    setCmsPages: (data) => (Object.assign({ type: 'SET_CMS_PAGES', data }))
}

export const Breadcrumbs = {
    setBreadcrumbs: (data) => (Object.assign({ type: 'SET_BREADCRUMBS', data }))
}

export const ActiveModal = {
    setActiveModal: (data) => (Object.assign({ type: 'SET_ACTIVE_MODAL', data }))
}

export const BonusInfo = {
    setBonusInfo: (data) => (Object.assign({ type: 'SET_BONUS_INFO', data }))
}

export const FavouriteGames = {
    setFavGames: (data) => (Object.assign({ type: 'SET_FAVOURITE_GAMES', data }))
}

export const GlobalMessages = {
    setSocketMessages: (data) => (Object.assign({ type: 'SET_SOCKET_MESSAGES', data }))
}
export const GameTags = {
    setGameTags: (data) => (Object.assign({ type: 'SET_TAGS', data })),
    resetTags: () => (Object.assign({ type: 'RESET_TAGS' }))
}

export default {}