import rootReducer from './reducers'
import { createStore } from 'redux'

// setup redux store
let store;
if (process.env.REACT_APP_NODE_ENV !== 'production' && process.env.REACT_APP_NODE_ENV !== 'disruptgaming') {
    store = createStore(
        rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
} else {
    store = createStore(rootReducer);
}

export default store;