import React from 'react';
import ReduxHelper from '../../../../helpers/redux.helper';
import ModalHelper from '@components/collection/components/globals/helpers/modal.helper';

class Step1Component extends React.Component {

    register() {
        this.props.parentCmp.formSubmitted = true;
        let allValid = true;

        let stepFieds = [
            this.props.parentCmp.formData['username'],
            this.props.parentCmp.formData['password'],
            this.props.parentCmp.formData['email'],
            this.props.parentCmp.formData['firstName'],
            this.props.parentCmp.formData['lastName']
        ];

        this.props.parentCmp.dispatchValidate(stepFieds, () => {
            stepFieds.forEach((field) => {
                if (field.getAttribute('is-valid') !== 'true') {
                    allValid = false;
                }
            });

            if (allValid === true) {
                this.props.parentCmp.dispatchRegister();
            }
        });
    }

    render() {
        return (
            <>
                <div className="step custom-scroll">
                    <ion-list>
                        <ion-item no-padding>
                            <ion-label color="label" position="floating">{this.props.translations['registration.username']}</ion-label>
                            <ion-input 
                                maxlength="20" 
                                autocomplete="new-password" 
                                type="text" 
                                color="black" 
                                ref={node => (this.props.parentCmp.formData.username = node)} 
                                onInput={() => this.props.parentCmp.dispatchValidate([this.props.parentCmp.formData.username])}
                                name="username" 
                            />
                        </ion-item>
                        <inline-error-component wrapper-id={'register-form'} field-name={'username'}></inline-error-component>

                        <ion-item no-padding>
                            <ion-label color="label" position="floating">{this.props.translations['registration.email']}</ion-label>
                            <ion-input 
                                type="email" 
                                maxlength="50" 
                                color="black" 
                                ref={node => (this.props.parentCmp.formData.email = node)} 
                                onInput={() => this.props.parentCmp.dispatchValidate([this.props.parentCmp.formData.email])} 
                                name="email"
                            />
                        </ion-item>
                        <inline-error-component wrapper-id={'register-form'} field-name={'email'}></inline-error-component>

                        <ion-item no-padding>
                            <ion-label color="label" position="floating">{this.props.translations['registration.password']}</ion-label>
                            <ion-input 
                                maxlength="20" 
                                autocomplete="new-password" 
                                type="password" 
                                color="black" 
                                ref={node => (this.props.parentCmp.formData.password = node)} 
                                onInput={() => this.props.parentCmp.dispatchValidate([this.props.parentCmp.formData.password])} 
                                name="password"
                            />
                        </ion-item>
                        <inline-error-component wrapper-id={'register-form'} field-name={'password'}></inline-error-component>

                        <ion-item no-padding>
                            <ion-label color="label" position="floating">{this.props.translations['registration.firstname']}</ion-label>
                            <ion-input
                                type="text"
                                color="black"
                                ref={node => (this.props.parentCmp.formData.firstName = node)}
                                onInput={() => this.props.parentCmp.dispatchValidate([this.props.parentCmp.formData.firstName])}
                                name="firstName"
                            />
                        </ion-item>
                        <inline-error-component wrapper-id={'register-form'} field-name={'firstName'}></inline-error-component>

                        <ion-item no-padding>
                            <ion-label color="label" position="floating">{this.props.translations['registration.lastname']}</ion-label>
                            <ion-input
                                type="text"
                                color="black"
                                ref={node => (this.props.parentCmp.formData.lastName = node)}
                                onInput={() => this.props.parentCmp.dispatchValidate([this.props.parentCmp.formData.lastName])}
                                name="lastName"
                            />
                        </ion-item>
                        <inline-error-component wrapper-id={'register-form'} field-name={'lastName'}></inline-error-component>
                    </ion-list>

                    <ion-button type="button" color="custom" class="next" onClick={() => this.register()}>{this.props.translations['registration.register.button']}</ion-button>
                </div>
            </>
        );
    }
}

export default ReduxHelper.connectWithStore(Step1Component, ['translations'])