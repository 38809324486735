import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './App';
import 'custom-event-polyfill'
import '@ionic/core/dist/collection/css/ionic.bundle.scss';
import './assets/fonts/custom-icomoon/style.css';
import './theme/reset.scss';
import './index.scss';
import { defineCustomElements as ionicELements } from '@ionic/core/loader';
import { defineCustomElements } from '@components/loader';
import devConfig from './config/development.config.json';
import prodConfig from './config/production.config.json';
import disruptGamingConfig from './config/disruptgaming.config.json';

import Config from './config/config';
import RestService from '@components/collection/components/globals/backend-services/rest.service';
import store from './redux/store';
import { GeneralConfig } from './redux/actions';
import LanguageHelper from './helpers/language.helper';
import LocalStorageService from '@components/collection/components/globals/backend-services/localStorage.service';
import { Workbox } from 'workbox-window';


// set development or production config
if (process.env.REACT_APP_NODE_ENV === 'production') {
    Config.set(prodConfig);
} else if (process.env.REACT_APP_NODE_ENV === 'disruptgaming') {
    Config.set(disruptGamingConfig);
}else {
    Config.set(devConfig);
}

console.log('Config.config', Config.config);

RestService.setConf(Config.config);

caches.delete('apiCache');

RestService.init(Config.config).then((generalConfig) => {
    store.dispatch(GeneralConfig.setConfig(generalConfig));

    if (!LocalStorageService.get('cms-page') || LocalStorageService.get('cms-page') === 'false') {
        LanguageHelper.setDefaultLanguage(store.getState().generalConfig.data);
    }

    LocalStorageService.set('cms-page', false);

    LanguageHelper.initPublicTranslations();
});

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
    () => {
        setTimeout(() => {
            initServiceWoker();
        }, 1500);
    }
);

ionicELements(window);
defineCustomElements(window);



function initServiceWoker() {
  console.log('initServiceWoker');
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      let showNewVersionModal = (registration) => {
          if (!registration.waiting) {
              // Just to ensure registration.waiting is available before
              // calling postMessage()
              return;
          }

          registration.update();
          registration.waiting.postMessage('skipWaiting');
          console.log("SKIP SKIP SKIP")

      }

      function onNewServiceWorker(registration, callback) {
          if (registration.waiting) {
              // SW is waiting to activate. Can occur if multiple clients open and
              // one of the clients is refreshed.
              return callback();
          }
          function listenInstalledStateChange() {
              registration.installing.addEventListener('statechange', function (event) {
                  if (event.target.state === 'installed') {
                      // A new service worker is available, inform the user
                      console.log('service worker is available, inform the user');
                      callback();
                  }
              });
          };
          if (registration.installing) {
              return listenInstalledStateChange();
          }
          // We are currently controlled so a new SW may be found...
          // Add a listener in case a new SW is found,
          registration.addEventListener('updatefound', listenInstalledStateChange);
      }

      var refreshing;
      // When the user asks to refresh the UI, we'll need to reload the window
      navigator.serviceWorker.addEventListener('controllerchange', function (event) {
          if (refreshing) return; // prevent infinite refresh loop when you use "Update on Reload"
          refreshing = true;
          if (document.querySelector('new-version-modal-component')) {
              var event = new CustomEvent('show', {
                  detail: {
                      swReg: {},
                      modalTitle: store.getState().translations.data['pwa.content.new.version.modal.title'],
                      modalContent:store.getState().translations.data['pwa.content.new.version.modal.content'],
                      buttonLabel:store.getState().translations.data['pwa.content.new.version.modal.btn.label'] 
                
                  }
              });

              // document.querySelector('new-version-modal-component').dispatchEvent(event);
          }
      });

      navigator.serviceWorker.register('/sw.js')
          .then(function (registration) {
              // Track updates to the Service Worker.
              if (!navigator.serviceWorker.controller) {
                  // The window client isn't currently controlled so it's a new service
                  // worker that will activate immediately
                  console.log("NO UPDATES");
                  return;
              }

              onNewServiceWorker(registration, function () {
                  showNewVersionModal(registration);
              });
          });
  }
}
