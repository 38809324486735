import React from 'react';
import './login.css';

export const LoginTemplate = function () {

    return (
        <form id="login-form" onSubmit={(e) => this.dispatchLogin(e)}>
            <ul className="login-details">
                <li>
                    <ion-item no-padding>
                        <ion-label color="label" position="floating">{this.props.translations['account.login.username.field']}</ion-label>
                        <ion-input
                            type="text"
                            color="black"
                            name="username"
                            ref={node => (this.formData.username = node)}
                        />
                    </ion-item>
                </li>

                <li>
                    <ion-item no-padding>
                        <ion-label color="label" position="floating">{this.props.translations['account.login.password.field']}</ion-label>
                        <ion-input
                            type="password"
                            color="black"
                            name="password"
                            ref={node => (this.formData.password = node)}
                        />
                    </ion-item>
                </li>
            </ul>
            <ion-button
                type="submit"
                color="custom">
                {this.props.translations['account.login.login.button']}
            </ion-button>
        </form>
    );
}