import React from 'react';
import RegisterService from '@components/collection/components/globals/backend-services/header/register.service';
import ReduxHelper from '../../../helpers/redux.helper'
import { Step1 } from './register-steps/steps';
import { RegisterTemplate } from './register.template';
import LocalStorageService from '@components/collection/components/globals/backend-services/localStorage.service';
import Config from '../../../config/config';
import HandlingErrorsHelper from '@components/collection/components/globals/helpers/handling-errors.helper';

class RegisterComponent extends React.Component {

    validators = {
        username: [
            {
                validator: 'required',
            },
            {
                validator: 'username',
            }
        ],
        password: [
            {
                validator: 'required'
            },
            {
                validator: 'customPasswordValidation'
            }
        ],
        email: [
            {
                validator: 'required'
            },
            {
                validator: 'email'
            },
            {
                validator: 'nospace'
            }
        ],
        firstName: [
            { validator: 'required' },
            { validator: 'nospace' }
        ],
        lastName: [
            { validator: 'required' },
            { validator: 'nospace' }
        ]
    };

    formData = {};
    formValidation;
    formSubmitted = false;
    stepperRef;
    isPasted = false;
    uniqueFields = Object.keys(this.validators).filter(field => this.props.generalConfig.registrationUniqueFields.split(",").includes(field));

    constructor(props) {
        super(props)

        this.state = {
            registerSuccess: '',
            registeredUser: null,
            errorMessage: '',
            stepOneCompleted: false
        }

        this.uniqueFields.forEach((uniqueField) => {
            this.validators[uniqueField].push({
                validator: 'unique',
                validatorParams: [uniqueField]
            })
        })

        this.validators = JSON.stringify(this.validators);
    }

    dispatchValidate = (fields, callback = null) => {
        var event = new CustomEvent('onValidate', {
            detail: {
                form: this.formData,
                fields: fields,
                onFinish: callback
            }
        });

        return this.formValidation.dispatchEvent(event);
    }

    registerData() {
        return {
            username: this.formData.username.value,
            email: this.formData.email.value,
            password: this.formData.password.value,
            currency: "EUR",
            country: "BG",
            firstName: this.formData.firstName.value,
            lastName: this.formData.lastName.value,
            dateOfBirth: "2000-12-12",
            acceptTermsAndConditions: true,
            acceptPromotions: true,
            acceptCommunicationPushMessage: true,
            acceptCommunicationCall: true,
            acceptCommunicationEmail: true,
            acceptCommunicationPost: true,
            acceptCommunicationSms: true,
            registrationDetailLevel: "STANDARD",
            lang: LocalStorageService.get("lang") || Config.defaultLanguage
        }
    }

    getAllData() {

        let data = this.registerData();

        // if (!data['affiliateCode']) {
        //     data['affiliateCode'] = UtilsHelper.getCookie('btag');
        // }

        return data;
    }

    toggleRegBtnDisabling = (disable) => {
        if (document.querySelector('.register-submit-button')) {
            document.querySelector('.register-submit-button').disabled = disable;
        }
    }

    dispatchRegister() {
        let data = this.getAllData();

        RegisterService.register(data).then((res) => {
            this.setState({ registerSuccess: this.props.translations['registration.success'] });
        }).catch((error) => {
            let handlingErrorsHelperInstance = new HandlingErrorsHelper(error, this.props.translations);
            handlingErrorsHelperInstance.getErrorMessage(false);
        })
    }

    initStepper(node) {
        if (node) {
            this.stepperRef = node;
            node.rootParams = this;
            node.steps = [Step1]
        }
    }

    render() {
        return RegisterTemplate.call(this);
    }
}

export default ReduxHelper.connectWithStore(RegisterComponent, ['generalConfig', 'translations', 'language'])
