import React, { Suspense, lazy, Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import ModalHelper from '@components/collection/components/globals/helpers/modal.helper';
import LocalStorageService from '@components/collection/components/globals/backend-services/localStorage.service';

/* ============================================  DESKTOP ROUTES ============================================ */
export const DESKTOP_ROUTES = {
    NON_LOGGED_ROUTES: [],
    PUBLIC_ROUTES: [],
    LOGGED_ROUTES: []
};
/* ============================================  COMMON ROUTES ============================================ */
export const COMMON_ROUTES = {

    LOGGED_ROUTES: [

    ],
    PUBLIC_ROUTES: [
        {
            src: (key) => <Redirect exact key={key} from="/" to={`/${LocalStorageService.get('lang') ? LocalStorageService.get('lang') : 'en'}/home`} />
        },
        {
            src: (key) => <Route key={key} path={`/:lang/register`} component={() => <OpenModalByRoute modal="register" />} />
        },
        {
            src: (key) => <Route key={key} path={`/:lang/login`} component={() => <OpenModalByRoute modal="login" />} />
        },
        {
            src: (key) => <Route key={key} path={`/:lang/home`} component={(props) => {
                const HomeComponent = lazy(() => import('./pages/home/home.component.jsx'));
                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <HomeComponent {...props} />
                    </Suspense>
                )

            }} />
        },
        {
            src: (key) => <Route key={key} path={`/:lang/client-area`} component={(props) => {
                const ClientAreaComponent = lazy(() => import('./pages/client-area/client.area.component'));
                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <ClientAreaComponent {...props} />
                    </Suspense>
                )
            }} />
        },
        {
            src: (key) => <Route key={key} path={`/:lang/contact`} component={(props) => {
                const ContactComponent = lazy(() => import('./pages/contact/contact.component.jsx'));
                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <ContactComponent {...props} />
                    </Suspense>
                )

            }} />
        },

        {
            src: (key) => <Route key={key} path={`/:lang/news/:selectedPage?`} component={(props) => {
                const BlogsComponent = lazy(() => import('./pages/blogs/blogs'));
                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <BlogsComponent {...props} type="NEWS" />
                    </Suspense>
                )
            }} />
        },
        {
            src: (key) => <Route key={key} path={`/:lang/solutions/:selectedPage?`} component={(props) => {
                const BlogsComponent = lazy(() => import('./pages/blogs/blogs'));
                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <BlogsComponent {...props} type="FEATURE" />
                    </Suspense>
                )
            }} />
        },
        {
            src: (key) => <Route key={key} path={`/:lang/game-designs`} component={(props) => {
                const GameDesignsComponent = lazy(() => import('./pages/game-designs/game-designs.component.jsx'));
                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <GameDesignsComponent {...props} />
                    </Suspense>
                )

            }} />
        },
        {
            src: (key) => <Route key={key} path={`/:lang/web-animations`} component={(props) => {
                const WebAnimationsComponent = lazy(() => import('./pages/game-designs/web-animations/web-animations.component.jsx'));
                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <WebAnimationsComponent {...props} />
                    </Suspense>
                )

            }} />
        },
        {
            src: (key) => <Route key={key} path={`/:lang/web-designs`} component={(props) => {
                const WebDesignsComponent = lazy(() => import('./pages/game-designs/web-designs/web-designs.component.jsx'));
                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <WebDesignsComponent {...props} />
                    </Suspense>
                )
            }} />
        },
        {
            src: (key) => <Route key={key} component={(props) => {
                LocalStorageService.set('cms-page', true);
                const CmsWrapperComponent = lazy(() => import('./pages/cms/cms-wrapper.component'));

                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <CmsWrapperComponent {...props} />
                    </Suspense>
                )

            }} />
        },
    ]
};
/* ============================================  MOBILE ROUTES ============================================ */
export const MOBILE_ROUTES = {
    LOGGED_ROUTES: [],
    NON_LOGGED_ROUTES: [],
    PUBLIC_ROUTES: []
};

class OpenModalByRoute extends Component {
    componentDidMount() {
        if (!ModalHelper.getSearchParam('modal')) {
            let searchUrl = new URLSearchParams(window.location.search);
            searchUrl.set('modal', ModalHelper.modals[this.props.modal].route);

            ModalHelper.router.push(`/${LocalStorageService.get('lang')}/home?${searchUrl}`);
        }
    }

    render() {
        return true;
    }
}