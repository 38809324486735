import React from 'react';
import { isMobile } from "react-device-detect";
import { navigationItems } from './../helpers/navigation.helper';
import LeftColumnComponent from './header/left-column.component.jsx';
import CookiesComponent from './cookies/cookies-component';
import RestrictedJurisdictionComponent from './restrictedJurisdiction/restrictedJurisdiction.component';


export const LayoutTemplate = function () {
    this.getLogos = () => {
        return [
            {
                'title': 'Payments',
                'items': [
                    {
                        'imageUrl': '../../assets/visa.png',
                    },
                    {
                        'imageUrl': '../../assets/mastercard.png',
                    },
                    {
                        'imageUrl': '../../assets/banking.png',
                    }
                ]
            },
            {
                'title': 'Trust',
                'items': [
                    {
                        'imageUrl': 'https://www.ggpoker.com/wp-content/uploads/2017/08/visa-dark@2x.png',
                        'itemHref': 'https://www.ggpoker.com/wp-content/uploads/2017/08/visa-dark@2x.png'
                    }
                ]
            },
            {
                'title': 'Social',
                'items': [
                    {
                        'imageUrl': 'https://www.ggpoker.com/wp-content/uploads/2017/08/visa-dark@2x.png',
                        'itemHref': 'https://www.ggpoker.com/wp-content/uploads/2017/08/visa-dark@2x.png'
                    }
                ]
            },
        ]
    }


    return (
        <div className={`animated-wrapper ${window.location.pathname.indexOf("casino-game-play") > -1 ? "hide-bottom-bar-only" : ""} ${(isMobile && window.location.pathname.indexOf('casino-game-play') > -1) ? 'hide-bottom-bar' : ''}`}>
            
            <ion-app>
                {
                    isMobile &&
                    <>
                        <modal-component  id="layout-modal-component"></modal-component>
                        
                        <ion-menu ref={menu => this.menu = menu} side="start" type="overlay">
                            {
                                this.state.banner &&
                                <banner-component show-points-on-banner={this.state.banner[0].showPointsOnBanner} draggable-banner={this.state.banner[0].draggableBanner} ref={this.slides.bind(this)}></banner-component>
                            }
                        </ion-menu>
                                <LeftColumnComponent items={navigationItems} toggleMenu={() => this.toggleMenu.bind(this)}></LeftColumnComponent>
                            <ion-content ref={(node) => this.content = node}>
                                {this.props.children}
                            </ion-content>

                    </>
                }

               {
                   !isMobile &&
                        <>
                            <modal-component modal-wrapper-class='custom-scroll' id="layout-modal-component"></modal-component>
                            <div className="ion-page" main="true">
                                <LeftColumnComponent />
                                <ion-content ref={(node) => this.content = node}>
                                    {this.props.children}
                                </ion-content>
                            </div>
                        </>
                        }
            </ion-app>

            <ion-modal-controller ref={(node) => {
                if (node && this.state.modalControllerRef === null) {
                    this.setState({ modalControllerRef: node })
                }
            }}></ion-modal-controller>
            <ion-toast-controller></ion-toast-controller>
            <ion-menu-controller></ion-menu-controller>

            <CookiesComponent></CookiesComponent>
            <RestrictedJurisdictionComponent></RestrictedJurisdictionComponent>
        </div>
    )
}