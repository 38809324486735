const user = (state = { data: {} }, action) => {
    switch (action.type) {
        case 'SET_USER_DATA':
            state = Object.assign(state, action);
            return Object.assign({}, state);
        case 'CHANGE_USER_DATA':
            state = Object.assign(state, action);
            return Object.assign({}, state);
        case 'LOGOUT':
            state = Object.assign(state, { data: {isLoggedIn: false }});
            return Object.assign({}, state);
        default:
            return state
    }
}


export default user