import React from 'react';
import './register.css';
import ModalHelper from '@components/collection/components/globals/helpers/modal.helper';

export const RegisterTemplate = function() {
    
    return (
        <div className="register">
            {!this.state.registerSuccess &&
                <form-validation-component ref={node => (this.formValidation = node)} validators={this.validators}>
                    <form autoComplete="off" className="register-form" id="register-form" ref={node => (this.formWrapper = node)}>
                        {
                            this.props.generalConfig.standardRegistrationEnabled === "true" &&
                                <stepper-component animated={false} ref={this.initStepper.bind(this)}></stepper-component>
                        }

                    </form>
                </form-validation-component>
            }

            {this.state.registerSuccess &&
                <div className="success-registration" dangerouslySetInnerHTML={{__html: this.state.registerSuccess}}></div>
            }
        </div>
    );
}