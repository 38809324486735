import LocalStorageService from '@components/collection/components/globals/backend-services/localStorage.service';

const UtilsHelper = {
    getValue: (refs, name) => refs[name] ? refs[name].value : null,
    removeEmptyValues(obj) {
        Object.keys(obj).forEach((key) => (obj[key] === null || obj[key] === 0) && delete obj[key]);

        return obj;
    },
    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    checkFrozenAcc(user) {
        return user.accountLockStatus === 'FROZEN';
    },
    toggleMobileNavigation() {
        var navigation = document.getElementsByClassName("mobile-navigation")[0];
        navigation.classList.toggle("active");

        var icon = document.getElementsByClassName("nav-icon1")[0];
        icon.classList.toggle("open");

        var backdrop = document.getElementsByClassName("collapsed-items")[0];
        backdrop.classList.toggle("open");
    },
    getUrlParameters() {

        let params = {};

        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) {
            params[key] = value;
        });

        return params;
    }
}

export default UtilsHelper;