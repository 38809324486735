import { combineReducers } from 'redux';
import user from './user';
import generalConfig from './generalConfig';
import customerConfig from './customerConfig';
import kycDocuments from './kycDocuments';
import defaultLimits from './defaultLimits';
import userSelfExclusions from './userSelfExclusions';
import userBalance from './userBalance';
import translations from './translations';
import language from './language';
import realityCheckTimer from './realityCheckTimer';
import providerMaintenanceMode from './providerMaintenanceMode';
import menus from './menus';
import cmspages from './cmsPages';
import breadcrumbs from './breadcrumbs';
import activeModal from './activeModal';
import bonusInfo from './bonusInfo';
import globalMessages from './globalMessages';
import favouriteGames from './favouriteGames';
import gameTags from './gameTags'

export default combineReducers({
    user,
    userBalance,
    generalConfig,
    customerConfig,
    language,
    kycDocuments,
    defaultLimits,
    userSelfExclusions,
    translations,
    realityCheckTimer,
    providerMaintenanceMode,
    menus,
    cmspages,
    breadcrumbs,
    activeModal,
    bonusInfo,
    favouriteGames,
    globalMessages,
    gameTags
})