import React from 'react';
import LoginService from '@components/collection/components/globals/backend-services/header/login.service';
import AlertHelper from '@components/collection/components/globals/helpers/alert.helper';
import ReduxHelper from '../../../helpers/redux.helper';
import { LoginTemplate } from './login.template';
import HandlingErrorsHelper from '@components/collection/components/globals/helpers/handling-errors.helper';
import ModalHelper from '@components/collection/components/globals/helpers/modal.helper';

class LoginComponent extends React.Component {

    formData = {
        username: null,
        password: null
    };

    dispatchLogin(e) {
        e.preventDefault();

        let usernameValue = this.formData.username.value;
        let passwordValue = this.formData.password.value;

        if(usernameValue === "" || passwordValue === ""){

            AlertHelper.showAlert({
                color: "danger",
                message: this.props.translations['login.fill.username.password.fields'],
            });

            return;
        }

        LoginService.login(usernameValue, passwordValue).then(() => {
            var event = new Event('LOGIN');
            document.body.dispatchEvent(event);
            ModalHelper.closeModal(ModalHelper.modals.login);
        }).catch((error) => {
            let handlingErrorsHelperInstance = new HandlingErrorsHelper(error, this.props.translations);

            AlertHelper.showAlert({ 
                message: handlingErrorsHelperInstance.getErrorMessage(true),
                color: "danger"
            });
        });
    }

    render() {
        return LoginTemplate.call(this);        
    }
}

export default ReduxHelper.connectWithStore(LoginComponent, ['translations', 'generalConfig'])