import React, { Suspense, lazy } from 'react';
import store from '../redux/store';

export const navigationItems = [
    {
        href: '/casino',
        title: 'Casino'
    },
    {
        href: '/live-casino',
        title: 'Live Casino'
    },
];

export function getSlugByLocale(LocalStorageService, cmspages, config, setBreadCrumbs) {
    let urlLang = window.location.pathname.split('/');
    let slugIndexStart = 1;


    config.supportedLanguages.split(',').forEach(lang => {
        if (window.location.pathname.indexOf('/'+lang) > -1) {
            slugIndexStart = 2;
            return false;
        }
    });

    const generateSlug = (locale, slug) => {
        let lang = locale;
        let result = null;
        let requestedPage = cmspages.filter(page => page.slug === slug)[0];
        // console.log("requestedPage", requestedPage);
        // no page with this slug
        if (!requestedPage) {
            result = { hasPage: false };

            result = {
                hasPage: false,
                url: `/${lang}/${slug}`
            }
            return result;
        }

        // let requestPageOrigin = JSON.parse(JSON.stringify(requestedPage));
        // search page by cookie lang
        if (requestedPage && requestedPage.locale !== lang) {
            // console.log("LANGUAGE IS DIFFERENT")
            requestedPage = cmspages.filter((page) => {
                let parentPageId = requestedPage.parentPageId || requestedPage.id;

                return page.locale === lang && parentPageId === (page.parentPageId || page.id);
            })[0];

            // console.log("requestedPage",requestedPage);

            if (requestedPage) {
                return { url: `/${lang}/${requestedPage.slug}` , requestedPage: requestedPage};
            } else {
                if(store.getState().translations) {
                    // AlertHelper.showAlert({message: store.getState().translations.data['page.notFound.message']});
                } else {
                    // AlertHelper.showAlert({message: 'Sorry, we can’t find the page you were looking for.'});
                }
                
                return { url: `/${lang}/casino`, requestedPage: null };
            }
        }

        // if there's no language in the url, redirect to localstorage lang
        if (config.supportedLanguages.split(',').indexOf(urlLang[1]) === -1) {
            return { url: `/${lang}/${requestedPage.slug}`, requestedPage: requestedPage };
        }

        if (!result) {
            result = `/${lang}/${requestedPage.slug}`;
        }

        return { url: result, requestedPage: requestedPage };
    }
    let url;
    let requestedPage;

    // check lang of the requested page
    let locale = LocalStorageService.get('lang');
    if (urlLang.length <= 2 && config.supportedLanguages.split(',').indexOf(urlLang[1]) > -1) {
        urlLang.push('casino');
    }
    if (urlLang[2] === '') {
        urlLang[2] = 'casino';
    }
    let lastPageUrl = generateSlug(locale, urlLang[urlLang.length - 1]).url;

    if (!lastPageUrl) {
        return {
            url: null,
            requestedPage: null
        }
    }

    locale = lastPageUrl.split('/')[1];
    let breadcrumbs = [];

    for (let i = slugIndexStart; i < urlLang.length; i++) {
        let result = generateSlug(locale, urlLang[i]);
        requestedPage = result.requestedPage;

        if (requestedPage) {
            breadcrumbs.push({
                url: requestedPage.slug,
                title: requestedPage.name
            });
        } else if (result.url) {
            breadcrumbs.push({
                url: result.url.split('/')[result.url.split('/').length - 1],
                title: result.url.split('/')[result.url.split('/').length - 1]
            });
        }

        if (result.url) {
            if (!url) {
                url = result.url.split('/')[1];
            }

            url += '/' + result.url.split('/')[2];
        }
    }

    setBreadCrumbs(breadcrumbs);

    return {
        url: '/' + url,
        requestedPage: requestedPage
    }
}


export function getProfileRoutes(generalConfig, customerConfig) {
    let profileRoute = [];

    profileRoute = [
        {
            name: 'account.user.myprofile', cmp: (props) => {
                const MyProfile = lazy(() => window.retry(() => import('../profile/myProfilePersonal/myProfilePersonal.component')));
                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <MyProfile {...props} />
                    </Suspense>
                )
            }, route: 'profile:my-profile', animation: {
                name: 'profile.json',
                width: 37,
                height: 37,
            }, icon: 'icon-profile', modal: null
        },{
            name: 'change.password.title', cmp: (props) => {
                const ChangePassword = lazy(() => window.retry(() => import('../profile/changePassword/changePassword.component')));
                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <ChangePassword {...props} />
                    </Suspense>
                )

            }, route: 'profile:change-password', icon: 'icon-change-pass', modal: null
        },
        {
            name: 'account.user.transactionhistory', cmp: (props) => {
                const HistoryComponent = lazy(() => window.retry(() => import('../profile/history/history.component')));
                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <HistoryComponent {...props} />
                    </Suspense>
                )
            }, route: 'profile:history', animation: {
                name: 'history.json',
                width: 37,
                height: 37,
            }, icon: 'icon-history', modal: null
        },
        {
            name: 'account.user.security.settings.title', cmp: (props) => {
                const SecurityComponent = lazy(() => window.retry(() => import('../profile/security/security.component')));
                return (
                    <Suspense fallback={<ion-spinner color="white" name="crescent"></ion-spinner>}>
                        <SecurityComponent {...props} />
                    </Suspense>
                )
            }, route: 'profile:security', icon: 'icon-lock', modal: null
        },
    ]

    return profileRoute;
}

