const kycDocuments = (state = { data: [] }, action) => {
    switch (action.type) {
        case 'SET_DOCUMENTS':
            state.data = action.data;
            return Object.assign({}, state);
        default:
            return state
    }
}

export default kycDocuments
