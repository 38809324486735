import React from 'react';
import ReduxHelper from '../../helpers/redux.helper'
import RestService from '@components/collection/components/globals/backend-services/rest.service'
import { CookiesTemplate } from './cookies.template';

class CookiesComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shown: false
        }
    }

    componentDidMount() {
        if (this.getCookie(RestService.config.apiDomain) !== 'accepted') {
            this.setState({ shown: true })
        }
    }

    setCookie = () => {
        document.cookie = `${RestService.config.apiDomain}=accepted;365;path=/`;
        this.setState({ shown: false })
    };

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];

            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    render() {
       return CookiesTemplate.call(this);
    }
}

export default ReduxHelper.connectWithStore(CookiesComponent, ['translations'])