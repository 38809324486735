
import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = (state, reducers) => {
    var obj = {};

    reducers.forEach((reducer) => {
        obj[reducer] = state[reducer].data
    })
    return obj;
};

const ReduxHelper = {
    connectWithStore(WrappedComponent, reducers = []) {
        let ConnectedWrappedComponent = connect((state) => mapStateToProps(state, reducers))(WrappedComponent)

        return function (props) {
            return <ConnectedWrappedComponent {...props} />
        }
    }
}

export default ReduxHelper;