import React from 'react';
import ReduxHelper from '../../helpers/redux.helper'
import './restrictedJurisdiction.css';

class RestrictedJurisdictionComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
    }

    componentDidMount() {
        if (this.props.generalConfig.currentIpIsForbidden) {
            this.setState({ show: true })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.generalConfig.currentIpIsForbidden !== this.props.generalConfig.currentIpIsForbidden) {
            this.setState({ show: true })
        }
    }

    render() {
        return (
            <div className={`blacklist ${(this.state.show ? 'blacklist-show' : '')}`}>
                <div className='shell'>
                    <div className='blacklist-inner'>
                        <ion-row>
                            <ion-col size="12">
                                <p>{this.props.translations['restricted.country.message']}</p>
                            </ion-col>
                        </ion-row>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReduxHelper.connectWithStore(RestrictedJurisdictionComponent, ['generalConfig', 'translations'])