import React from 'react';
import { NavLink } from 'react-router-dom'
import { isMobile } from "react-device-detect";
import CmsMenus from '@components/collection/components/globals/backend-services/header/cms-menu.service';
import ReduxHelper from '../../../helpers/redux.helper';
import LocalStorageService from '@components/collection/components/globals/backend-services/localStorage.service';
import { Menus } from '../../../redux/actions';
import { NavigationTemplate } from './navigation.template';
import UtilsHelper from '../../../helpers/utils.helper';

const MENU_TARGETS = {
    "Home page": "/home",
    "Solutions page": "/solutions",
    "News page": "/news",
    "Contact page": "/contact"
}

class NavigationComponent extends React.Component {
    state = {
        activeMenu: [],
    }

    menuLinks = [];
    arrayToPush;
    level = 0;
    locationPath = null
    menuLocale;

    componentDidMount() {
        window['manualUpdateNavigation'] = () => {
            this.forceUpdate();
        };
    }

    componentDidUpdate() {
        
        // if(this.props.location.pathname != prevProps.location.pathname) {
        //     console.log("ONKKKK")
        // }
        if ( Object.keys(this.props.menus.nav).length > 0) {
            this.setActiveMenu(this.props.menus.nav.filter(item => item.cmsMenuLocation === 'MAIN_MENU'))
        }

        if (LocalStorageService.get('lang') !== this.menuLocale) {
            this.menuLocale = LocalStorageService.get('lang');
            CmsMenus.getMenus(LocalStorageService.get('lang')).then((response) => {
                response = response.filter(item => item.cmsMenuLocation === 'MAIN_MENU' || item.cmsMenuLocation === 'FOOTER_MENU');

                if (response.length > 0) {
                    this.generateMenuLinks(response, '', true);
                    this.props.dispatch(Menus.setMenus({ nav: response }));
                    if (this.props.cmspages.length === 0) {
                        setTimeout(() => {
                            this.setActiveMenu(response.filter(item => item.cmsMenuLocation === 'MAIN_MENU'));
                            this.forceUpdate();
                        }, 2000);
                        return;
                    }
                    this.setActiveMenu(response.filter(item => item.cmsMenuLocation === 'MAIN_MENU'));
                }
            });
        }



    }

    setActiveMenu(response) {
        this.locationPath = window.location.pathname;
        let activeMenuFound = false;
        response.forEach((menu) => {
            if (menu.link === '/' && (this.locationPath === `/${LocalStorageService.get('lang')}/home` || this.locationPath === '/')) {
                if(this.props.menus.activeMenu.id !== menu.id) {
                    this.props.dispatch(Menus.setActiveMenu({ activeMenu: menu }));
                    this.forceUpdate();
                }
                activeMenuFound = true;
                return true;
            } else if (menu.link !== '/' && this.locationPath.indexOf(`/${LocalStorageService.get('lang')}${menu.link}`) > -1) {
                if(this.props.menus.activeMenu.id !== menu.id) {
                    this.props.dispatch(Menus.setActiveMenu({ activeMenu: menu }));
                    this.forceUpdate();
                }
                activeMenuFound = true;
                return false;
            }


            let rootMenu = menu;


            let loopChilds = (menu) => {
                menu.subMenus.forEach(child => {

                    let page = this.props.cmspages.filter(item => item.slug === this.locationPath.split('/').pop());

                    if (page.length > 0) {
                        if (child.cmsPageId === (page[0].parentPageId || page[0].id)) {
                            this.props.dispatch(Menus.setActiveMenu({ activeMenu: rootMenu }));

                            return false;
                        }
                    }

                    if (child.subMenus.length > 0) {
                        loopChilds(child);
                    }
                });
            }

            if (rootMenu.subMenus.length > 0) {
                loopChilds(rootMenu);
            }
        });

        if(activeMenuFound === false && this.props.menus.activeMenu.id !== response.filter(menu => menu.link === '/')[0].id) {
            this.props.dispatch(Menus.setActiveMenu({ activeMenu: response.filter(menu => menu.link === '/')[0] }));
            this.forceUpdate();
        }
        
    }

    generateMenuLinks(menus, path = '', isRoot = false) {
        menus.forEach(menu => {
            if (isRoot === true) {
                path = '';
            }


            menu.link = path + '/' + menu.link;

            if (menu.subMenus.length > 0) {
                this.generateMenuLinks(menu.subMenus, menu.link);
            }

            menu.link = '/' + menu.link.replace(/^[/]+/g, '');
        });
    }


    getMenuLink(menuItem, isRoot = false) {
        let isLink = menuItem.link.match(new RegExp(/(http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?/));
        menuItem.isRoot = isRoot;

        if(menuItem.link === "/client-area"){
            if(this.props.user && this.props.user.isLoggedIn){
                return this.renderLink(`${menuItem.link}`, menuItem);
            }else{
                return this.renderLink(`/login`, menuItem);
            }
        }

        if (isLink) {
            menuItem.isExternal = true;
            return this.renderLink(isLink[0], menuItem)
        }

        if (menuItem.link === '/') {
            return this.renderLink(this.locationPath === '/' ? menuItem.link : `/home`, menuItem)
        }
        if (menuItem.link.substr(0, 1) === '/') {
            return this.renderLink(menuItem.link, menuItem);
        }

        if (menuItem.linkType === "CMS_PAGE") {
            return this.renderLink(`/${MENU_TARGETS[menuItem.message] || menuItem.link}`, menuItem)
        } else {
            return this.renderLink(`/${menuItem.link}`, menuItem)
        }
    }

    renderLink(url, menuItem) {

        console.log(url);
        return (
            menuItem.isExternal ?
                <a href={url}><span>{menuItem.message}</span></a>
                :
                (
                    isMobile ?
                        <NavLink
                            to={'/' + LocalStorageService.get('lang') + url}
                            activeClassName="selected"
                            isActive={() => this.props.menus.activeMenu ? this.props.menus.activeMenu.id === menuItem.id : false}
                            onClick={() => {
                                UtilsHelper.toggleMobileNavigation();

                                 if(menuItem.isRoot === true) {
                                    this.props.dispatch(Menus.setActiveMenu({ activeMenu: menuItem }))
                                 }
                            }}
                            key={url}
                            className={menuItem.subMenus.length > 0 ? 'submenu-wrapper' : ''}>
                            <span>{menuItem.message}</span>

                        </NavLink>
                        :
                            <NavLink
                            activeClassName="selected"
                            key={url}
                            isActive={() => this.props.menus.activeMenu.id === menuItem.id}
                            to={'/' + LocalStorageService.get('lang') + url}
                            onClick={() => menuItem.isRoot === true ? this.props.dispatch(Menus.setActiveMenu({ activeMenu: menuItem })) : true} >
                            <span>{menuItem.message}</span>
                        </NavLink>
                )
        )
    }

    attachClick(e) {
        e.preventDefault();

        let menuItem = e.target.closest('div')['nextElementSibling'];
        let parentMenu = e.target.closest('ul');

        if (menuItem === null) {
            return;
        }

        if (menuItem.style.maxHeight) {
            menuItem.style.maxHeight = null;
            menuItem.style.overflow = "hidden";
            menuItem.style.visibility = "hidden";
        } else {
            if (parentMenu.style.maxHeight) {
                parentMenu.style.maxHeight = parentMenu.scrollHeight + menuItem.scrollHeight + "px";
            }
            menuItem.style.maxHeight = menuItem.scrollHeight + "px";
            menuItem.style.overflow = "visible";
            menuItem.style.visibility = "visible";
        }
        e.target['classList'].toggle("active");
    }

    render() {
        return NavigationTemplate.call(this);
    }
}

export default ReduxHelper.connectWithStore(NavigationComponent, ['language', 'generalConfig', 'translations', 'menus', 'activeModal', 'user', 'breadcrumbs', 'cmspages'])