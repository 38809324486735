import ReactDOM from 'react-dom';
import React, { Suspense, lazy } from 'react';
import store from '../redux/store';
import { isBrowser, isMobile } from 'react-device-detect';
import { Provider } from 'react-redux'
import ModalHelper from '@components/collection/components/globals/helpers/modal.helper';
import RegisterComponent from '../layout/header/register/register.component';
import LoginComponent from './../layout/header/login/login.component';

const MODALS_CONFIG = [
    {
        name: 'register',
        color: 'warning',
        cmp: lazy(() => import('../layout/header/register/register.component')),
        route: 'register',
        toolbarOptions: {
            shown: true,
            title: 'registration.introduction',
            hasBackButton: false
        }
    },
    {
        color: 'white',
        name: 'tac',
        options: {
            backdropDismiss: false,
            cssClass: 'terms-conditions-modal',
        },
        cmp: lazy(() => import('../layout/acceptTermsConditions/acceptTermsConditions.component')),
        toolbarOptions: {
            shown: false,
            title: '',
            hasBackButton: false
        }
    },
    {
        name: 'activateAccount',
        color: 'white',
        cmp: lazy(() => import('../layout/activate/activate.component')),
        route: 'activate-account',
        toolbarOptions: {
            shown: true,
            title: '',
            hasBackButton: false
        }
    },
    {
        name: 'forgotPassword',
        color: 'white',
        options: {
            cssClass: 'forgot-password',
        },
        cmp: lazy(() => import('../layout/header/forgot-password/forgot-password.component')),
        route: `forgot-password`,
        toolbarOptions: {
            shown: true,
            title: 'forgot.password.title',
            hasBackButton: false
        }
    },
    {
        name: 'resetPassword',
        cmp: lazy(() => import('../layout/header/reset-password/reset-password.component')),
        route: 'reset-password',
        color: 'white',
        options: {
            cssClass: 'reset-password',
        },
        toolbarOptions: {
            shown: true,
            title: 'reset.password.title',
            hasBackButton: false
        }
    },
    {
        name: 'profile',
        cmp: lazy(() => import('../profile/profile-wrapper.component')),
        route: 'profile',
        options: {
            cssClass: 'full-modal',
            showBackdrop: false,
            backdropDismiss: false,
        },
        toolbarOptions: {
            shown: isMobile,
            title: '',
            hasBackButton: isMobile
        }
    },
    {
        name: 'profile-options',
        cmp: lazy(() => import('../layout/profile-modal-mobile')),
        route: "profile-options",
        options: {
            cssClass: 'full-modal',
        },
        toolbarOptions: {
            shown: false,
            title: '',
            hasBackButton: false
        }
    },
    {
        name: 'login',
        options: {
            cssClass: 'login',
        },
        cmp: lazy(() => import('./../layout/header/login/login.component')),
        route: 'login',
        toolbarOptions: {
            shown: true,
            title: 'login.modal.title',
            hasBackButton: false
        }
    },
    {
        name: 'changeLangModal',
        cmp: lazy(() => import('../profile/changeLang/changeLang.component')),
        options: {
            cssClass: 'change-lang-modal',
        },
        toolbarOptions: {
            shown: true,
            title: 'account.user.navigation.changelanguage.title',
            hasBackButton: true,
            backModalName: 'profile-options'
        }
    },
    {
        name: 'socketModal',
        cmp: (params) => {
            ModalHelper.modals.socketModal.options.cssClass = params.componentTag;
            if (params.componentTag === 'socket-criticalEvents') {
                ModalHelper.modals.socketModal.options.backdropDismiss = false
            } else {
                ModalHelper.modals.socketModal.options.backdropDismiss = true
            }

            return params.componentTag;
        },
        toolbarOptions: {
            shown: false,
            title: '',
            hasBackButton: false
        }
    },
];

export class Modal {
    modalCmp;
    options;

    constructor(modalCmp, options = {}) {
        this.modalCmp = modalCmp;
        this.options = options;
    }

    renderComponent = (selector, params) => {
        return this.generateModal(this.modalCmp, selector, params)
    }

    getModalObject = (modalConfig) => {
        return ModalHelper.createModalObject(modalConfig, this.options, this.renderComponent)
    }
    generateModal = (cmp, selector, props) => {
        if(cmp === 'register') {

            return ReactDOM.render(
                <Provider store={store}>
                      {this.options.toolbarOptions.shown && <toolbar-component
                        title={store.getState().translations.data[this.options.toolbarOptions.title]}
                        close-modal-name={this.options.name}
                        icon-path-x="/assets/icons"
                        is-back-button-needed={this.options.toolbarOptions.hasBackButton}>
                    </toolbar-component>}

                    <RegisterComponent />
                </Provider>

            , selector)
        }

        if(cmp==='login') {
            return ReactDOM.render(
                <Provider store={store}>
                      {this.options.toolbarOptions.shown && <toolbar-component
                        title={store.getState().translations.data[this.options.toolbarOptions.title]}
                        close-modal-name={this.options.name}
                        icon-path-x="/assets/icons"
                        icon-path-back="/assets/icons"
                        is-back-button-needed={this.options.toolbarOptions.hasBackButton}
                        back-button-title={isBrowser ? store.getState().translations.data['registration.back.button'] : ''}
                        back-modal-name={this.options.name === 'profile' ? 'profile-options' : ''}>
                    </toolbar-component>}
                    {
                        props && props.gameFriendlyUrl ? <LoginComponent gameFriendlyUrl={props.gameFriendlyUrl} /> : <LoginComponent />
                    }
                </Provider>

            , selector)
        }

        return ReactDOM.render(
            <Provider store={store}>
                <Suspense fallback={<ion-spinner color="dark" name="crescent"></ion-spinner>}>
                    {this.options.toolbarOptions.shown && <toolbar-component class={this.options.name === 'profile' && isMobile ? "user-profile-toolbar" : ""}
                        title={store.getState().translations.data[this.options.toolbarOptions.title]}
                        close-modal-name={this.options.name}
                        icon-path-x="/assets/icons"
                        icon-path-back="/assets/icons"
                        is-back-button-needed={this.options.toolbarOptions.hasBackButton}
                        back-button-title={isBrowser ? store.getState().translations.data['registration.back.button'] : ''}
                        back-modal-name={this.options.name === 'profile' ? 'profile-options' : this.options.toolbarOptions.backModalName}
                        color-title-and-icon={this.options.name === 'gameFilters' ? true : false}
                        elements-class={this.options.name === 'gameFilters' ? ".filter-with-color" : ""}
                        class-to-be-removed={this.options.name === 'gameFilters' ? "lightBlue" : ""}
                    >
                    </toolbar-component>}

                    {
                        typeof cmp !== 'function' &&
                        React.createElement(cmp, props)
                    }
                    {
                        typeof cmp === 'function' &&
                        React.createElement(cmp(props), props)
                    }
                </Suspense>
            </Provider>
            , selector);
    }

}

export const MODALS = (() => {
    let modalsObj = {};
    MODALS_CONFIG.map(modal => new Modal(modal.cmp, modal)).forEach((modalRef, index) => {
        modalsObj[MODALS_CONFIG[index].name] = modalRef.getModalObject(MODALS_CONFIG[index]);
    });

    return modalsObj
})()