const userBalance = (state = { data: {} }, action) => {
    switch (action.type) {
        case 'SET_USER_BALANCE':
            state = Object.assign(state, action);
            return Object.assign({}, state);
        default:
            return state
    }
}


export default userBalance