const gameTags = (state = { data: [] }, action) => {
    switch (action.type) {
        case 'SET_TAGS':
            state.data = action.data;
            return Object.assign({}, state);
        case 'RESET_TAGS':
            return state = { data: [] };
        default:
            return state
    }
}

export default gameTags;