import store from '../redux/store';
import TranslationService from '@components/collection/components/globals/backend-services/translations.service';
import LocalStorageService from '@components/collection/components/globals/backend-services/localStorage.service';
import { Language } from '../redux/actions';
import ModalHelper from '@components/collection/components/globals/helpers/modal.helper';
import Config from './../config/config';

// const BODY = document.querySelector('body')

const LanguageHelper = {
    setDefaultLanguage: (config) => {
        let locationPath = window.location.pathname.split('/');
        let urlLang = locationPath[1];
        let lang = LocalStorageService.get('lang');

        if (config.supportedLanguages.split(',').indexOf(urlLang) > -1 && !lang) {
            LocalStorageService.set('lang', urlLang);
            document.querySelector('body').classList.add(urlLang)

        } else {
            LocalStorageService.set('lang', lang || config.defaultLanguage);
            document.querySelector('body').classList.add(lang || config.defaultLanguage)
        }
    },
    setLanguage(config, lang) {
        let locationPath = window.location.pathname.split('/');
        let urlLang = locationPath[1];
        LocalStorageService.set('lang', lang);
        document.querySelector('body').classList.remove(urlLang)
        document.querySelector('body').classList.add(lang)

        if (config.supportedLanguages.split(',').indexOf(urlLang) > -1 && urlLang !== lang) {
            ModalHelper.router.push(window.pathname.replace(`/${urlLang}/`, `/${lang}/`));
        }
    },
    initPublicTranslations() {
        let lang = LocalStorageService.get('lang');
        store.dispatch(Language.setLang(lang));
        // console.log('CONFIG', Config)
        TranslationService.initV2(lang, Config.config.translationsConfig.public.join(','))
    },
    initPrivateTranslations() {
        let lang = LocalStorageService.get('lang');
        store.dispatch(Language.setLang(lang));
        TranslationService.initV2(lang, Config.config.translationsConfig.private.join(','))
    },
    initTranslations() {
        let lang = LocalStorageService.get('lang');
        store.dispatch(Language.setLang(lang));

        TranslationService.init(lang);
    }
}

export default LanguageHelper;