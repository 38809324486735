import React from 'react';
import ReduxHelper from '../../helpers/redux.helper'
import { LeftColumnTemplate } from './left-column.template';

class LeftColumnComponent extends React.Component {

    render() {
        return LeftColumnTemplate.call(this);
    }
}

export default ReduxHelper.connectWithStore(LeftColumnComponent, ['user', 'generalConfig', 'translations', 'language', 'realityCheckTimer', 'menus'])