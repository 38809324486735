import React from 'react';
import './left-column.css';
import { isBrowser, isMobile } from "react-device-detect";
import { NavLink } from 'react-router-dom';
import NavigationComponent from './navigation/navigation.component';
import { Menus } from '../../redux/actions';
import UtilsHelper from '../../helpers/utils.helper';
import LocalStorageService from '@components/collection/components/globals/backend-services/localStorage.service';

export const LeftColumnTemplate = function () {

    this.logout = () => {
        let event = new CustomEvent('LOGOUT');
        document.body.dispatchEvent(event);
    }

    return (
        <left-column-component ref={leftColumn => this.leftColumn = leftColumn} is-collapsable={false} autocollapse={false} expanded={isMobile ? false : true} paddings={'0'}>
            <div className="collapsed-items">
                {isMobile &&
                    <>
                        <div className="mobile-wrapper">
                            <NavLink
                                to={`/${this.props.language}/home`}
                                onClick={() => {
                                    this.props.dispatch(Menus.setActiveMenu(this.props.menus.nav.filter(menu => menu.link === '/')[0]))
                                }
                                } className="logo-black">
                                <img alt="logo" src={require('../../assets/dench logo white.svg')} />
                            </NavLink>
                            {
                                this.props.user && this.props.user.isLoggedIn && <div className="user-greeting">
                                    {this.props.translations['account.login.user.greeting']}, {this.props.user.username}
                                </div>
                            }
                            <div className="nav-icon1" onClick={() => UtilsHelper.toggleMobileNavigation()}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="mobile-navigation" >
                            <NavigationComponent />
                            {this.props.user && this.props.user.isLoggedIn &&
                                <div className="logout mobile-logged-users" onClick={() => this.logout()}>
                                    {this.props.translations['account.login.user.logout']}
                                </div>
                            }
                            <div className="linkedin-logo">
                                <a href="https://www.linkedin.com/company/dench-egaming-solutions">
                                    <img src={require('../../assets/linkedin.svg')} alt="linkedin-logo" />
                                </a>
                            </div>
                        </div>
                    </>
                }
            </div>
            {isBrowser &&
                <div className="expanded-items">
                    <>
                        <div className="header-wrapper">

                            <div className="header-main">
                                <NavLink
                                    to={`/${this.props.language}/home`}
                                    onClick={() => this.props.dispatch(Menus.setActiveMenu(this.props.menus.nav.filter(menu => menu.link === '/')[0]))} className="logo">
                                    <img alt="logo" src={require('../../assets/dench logo white.svg')} />
                                </NavLink>
                                {
                                    this.props.user && this.props.user.isLoggedIn && <div className="user-greeting">
                                        {this.props.translations['account.login.user.greeting']}, {this.props.user.username}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="header-navigation">
                            <NavigationComponent />
                        </div>

                        <div className="linkedin-logo">
                            <a href="https://www.linkedin.com/company/dench-egaming-solutions">
                                <img src={require('../../assets/linkedin.svg')} alt="linkedin-logo" />
                            </a>
                        </div>
                    </>
                </div>
            }
        </left-column-component>
    );
}