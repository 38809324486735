import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import LayoutComponent from './layout/layout.component';
import store from 'redux/store';
import CmsService from '@components/collection/components/globals/backend-services/cms/cms.service';
import { CMSPages } from './redux/actions';
import RoutesComponent from './routes.component';
import ModalHelper from '@components/collection/components/globals/helpers/modal.helper';

class App extends Component {
    state = {
        translationsLoaded: false,
        modalsLoaded: false
    }

    routes = [];

    checkTranslationsLoaded() {
        if (this.state.translationsLoaded === false && Object.keys(store.getState().translations.data).length > 0) {
            this.setState({ translationsLoaded: true });
            return true;
        }
        return false;
    }

    loadCms() {
        CmsService.getAll().then((response) => {
            store.dispatch(CMSPages.setCmsPages(response));
        });
    }

    checkModalsLoaded() {
        if (this.state.modalsLoaded !== true) {
            if (!ModalHelper.modals) {
                setTimeout(() => this.checkModalsLoaded(), 200);
            } else {
                this.setState({ modalsLoaded: true });
            }
        }
    }

    componentDidMount() {
        this.checkTranslationsLoaded();
        this.checkModalsLoaded();
        this.loadCms();

        let unsubscribeStore = store.subscribe(() => {
            if (this.checkTranslationsLoaded()) {
                unsubscribeStore();
            }
        });

        this.routes = <RoutesComponent></RoutesComponent>
    }

    render() {
        return (
            <>
                <BrowserRouter>
                    <Switch>
                        <LayoutComponent translationsLoaded={this.state.translationsLoaded}>
                            <div className="main-content">
                                {this.state.translationsLoaded === true &&
                                    this.state.modalsLoaded &&
                                    this.routes
                                }
                            </div>
                        </LayoutComponent>
                    </Switch>
                </BrowserRouter>
                <new-version-modal-component
                    color-confirm="first"
                    title-color="contrast"
                />
            </>
        );
    }
}
export default App;
