const bonusInfo = (state = { data: [] }, action) => {
    switch (action.type) {
        case 'SET_BONUS_INFO':
            state = Object.assign(state, action);
            return Object.assign({}, state);
        default:
            return state
    }
}

export default bonusInfo;