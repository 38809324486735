import React from 'react';
import Step1Component from './step1';
import ReactDOM from 'react-dom';
import store from '../../../../redux/store';
import { Provider } from 'react-redux'

export class Step1 extends HTMLElement {
    connectedCallback() {
        ReactDOM.render(<Provider store={store}><Step1Component parentCmp={this.parentNode.rootParams} /></Provider>, this)
    }
}