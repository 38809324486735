import React, { Component } from 'react';
import { DESKTOP_ROUTES, COMMON_ROUTES, MOBILE_ROUTES } from './routes';
import store from 'redux/store';
import { Switch } from 'react-router-dom';

class RoutesComponent extends Component {
    state = {
        isLoggedIn: null,
    }

    unsubscribe;

    checkisLoggedIn() {
        if (Object.keys(store.getState().user.data).length > 0 && typeof (store.getState().user.data['isLoggedIn']) !== 'undefined') {

            if (store.getState().user.data.isLoggedIn !== this.state.isLoggedIn) {
                this.setState({ isLoggedIn: store.getState().user.data.isLoggedIn });
            }
            return store.getState().user.data.isLoggedIn;
        }
        return null;
    }

    componentDidMount() {
        this.checkisLoggedIn();

        this.unsubscribe = store.subscribe(() => {
            if (this.checkisLoggedIn() !== null) {
                if (this.state.isLoggedIn === true) {
                }
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    render() {
        return (
            // this.props.isMobile ?
            //     <Switch>
            //         {this.state.isLoggedIn === false &&
            //             MOBILE_ROUTES.NON_LOGGED_ROUTES.map((route, i) => { return route.src(i) })
            //         }
            //         {this.state.isLoggedIn === false &&
            //             COMMON_ROUTES.NON_LOGGED_ROUTES.map((route, i) => { return route.src(i) })
            //         }

            //         {this.state.isLoggedIn === true &&
            //             MOBILE_ROUTES.LOGGED_ROUTES.map((route, i) => { return route.src(i) })
            //         }
            //         {this.state.isLoggedIn === true &&
            //             COMMON_ROUTES.LOGGED_ROUTES.map((route, i) => { return route.src(i) })
            //         }
            //     </Switch>
            //     :
                <Switch>
                    {/* {this.state.isLoggedIn === false &&
                        DESKTOP_ROUTES.NON_LOGGED_ROUTES.map((route, i) => { return route.src(i) })
                    }
                    {this.state.isLoggedIn === false &&
                        COMMON_ROUTES.NON_LOGGED_ROUTES.map((route, i) => { return route.src(i) })
                    }

                    {this.state.isLoggedIn === true &&
                        DESKTOP_ROUTES.LOGGED_ROUTES.map((route, i) => { return route.src(i) })
                    } */}
                    {this.state.isLoggedIn === true &&
                        COMMON_ROUTES.LOGGED_ROUTES.map((route, i) => { return route.src(i) })
                    }
                    {COMMON_ROUTES.PUBLIC_ROUTES.map((route, i) => { return route.src(i) })}
                </Switch>
        );
    }
}
export default RoutesComponent;
