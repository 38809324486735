import React from 'react';
import { isBrowser } from "react-device-detect";
import { NavLink } from 'react-router-dom';
import UtilsHelper from '../../../helpers/utils.helper';
import './navigation.css';

export const NavigationTemplate = function () {

    this.renderMenu = () => {
        return <ul>
            <>
                {this.props.menus.nav.length &&
                    this.renderMenuItems(this.props.menus.nav.filter(item => item.cmsMenuLocation === 'MAIN_MENU'), true)
                }
            </>
        </ul>
    }

    this.renderSubmenusForDesktop = (cmsMenus) => {
        return <ul className="sub-menu">
            {this.renderMenuItems(cmsMenus)}
        </ul>
    }

    this.renderMainMenuForDesktop = (cmsMenus) => {
        return cmsMenus.map(menu => this.getMenuLink(menu, true))
    }

    this.logout = () => {
        let event = new CustomEvent('LOGOUT');
        document.body.dispatchEvent(event);
    }

    this.renderMenuItems = (cmsMenus, isRoot = false) => {

        return cmsMenus.map((menu, i) =>
            <li key={i}>
                <div className={menu.subMenus.length > 0 ? 'has-child' : ''}>
                    {this.getMenuLink(menu, isRoot)}
                </div>

                {
                    menu.subMenus.length > 0 && <ul key={i} className="sub-menu">
                        {this.renderMenuItems(menu.subMenus)}
                    </ul>
                }
            </li>
        )
    }

    return (
        <>
            {isBrowser &&
                <>
                    <div className="top-navigation">
                        {this.props.menus.nav.length > 0 &&
                            this.renderMainMenuForDesktop(this.props.menus.nav.filter(item => item.cmsMenuLocation === 'MAIN_MENU'))
                        }
                    </div>
                    {this.props.user && this.props.user.isLoggedIn &&
                        <div className="logged-users" onClick={() => this.logout()}>
                            {this.props.translations['account.login.user.logout']}
                        </div>
                    }
                </>
            }
            <div className="navigation">
                {isBrowser ?
                    <div className="nav">{
                        this.renderSubmenusForDesktop(Object.keys(this.props.menus.activeMenu).length ? this.props.menus.activeMenu.subMenus : [])
                    }</div>
                    : this.renderMenu()}
            </div>
        </>
    );
}