import React from 'react';
import './cookies-component.css'

export const CookiesTemplate = function() {
    
    return (
        <div className={`cookies ${(this.state.shown ? 'cookies-shown' : '')}`}>
            <div className='shell'>
                <div className='cookies-inner'>
                    <ion-row>
                        <ion-col size-xs="12" size-md="10">
                            <p dangerouslySetInnerHTML={{__html: this.props.translations['cookies.text.content']}}></p>
                        </ion-col>
                        <ion-col size-xs="12" size-md="2" align-self-center text-center>
                            <ion-button color="buttonGray" onClick={() => this.setCookie()}>{this.props.translations['cookies.button.accept']}</ion-button>
                        </ion-col>
                    </ion-row>
                </div>
            </div>
        </div>
    )
}