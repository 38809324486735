const menus = (state = { data: { nav: [], activeMenu: {}} }, action) => {
    switch (action.type) {
        case 'SET_MENUS':
            state.data.nav = Object.assign(state.data.nav, action.data.nav);
            return Object.assign({}, state);
        case 'SET_ACTIVE_MENU':
            state.data.activeMenu = Object.assign(state.data.activeMenu, action.data.activeMenu);
            return Object.assign({}, state);
        default:
            return state
    }
}

export default menus